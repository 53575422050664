const compareFunc = (a, b, sortBy) => {
  if (sortBy === "A-Z") {
    return a.node.title < b.node.title ? -1 : 1
  } else if (sortBy === "Z-A") {
    return a.node.title > b.node.title ? -1 : 1
  } else if (sortBy === "low-players") {
    return a.node.currentPlayersCount < b.node.currentPlayersCount ? -1 : 1
  } else if (sortBy === "high-players") {
    return a.node.currentPlayersCount > b.node.currentPlayersCount ? -1 : 1
  } else if (sortBy === "low-price") {
    return Math.min.apply(
      Math,
      a.node.affiliates.map(price => price.price)
    ) <
      Math.min.apply(
        Math,
        b.node.affiliates.map(price => price.price)
      )
      ? -1
      : 1
  } else if (sortBy === "high-price") {
    return Math.min.apply(
      Math,
      a.node.affiliates.map(price => price.price)
    ) >
      Math.min.apply(
        Math,
        b.node.affiliates.map(price => price.price)
      )
      ? -1
      : 1
  } else if (sortBy === "high-average") {
    let totalA = 0
    let totalB = 0

    a.node.timestamps.slice(-24).map(({ y }) => (totalA = totalA + y))
    b.node.timestamps.slice(-24).map(({ y }) => (totalB = totalB + y))

    return Math.round(totalA / 24) > Math.round(totalB / 24) ? -1 : 1
  } else if (sortBy === "low-average") {
    let totalA = 0
    let totalB = 0

    a.node.timestamps.slice(-24).map(({ y }) => (totalA = totalA + y))
    b.node.timestamps.slice(-24).map(({ y }) => (totalB = totalB + y))

    return Math.round(totalA / 24) < Math.round(totalB / 24) ? -1 : 1
  } else {
    return 0
  }
}

export default compareFunc
