import React from "react"
import Select from "react-select"
import { customStylesHomePage } from "../styles/customSelectStyles"

const SortSearch = ({ setSortBy }) => {
  const sortOptions = [
    { value: "A-Z", label: "Alphabetical: A-Z" },
    { value: "Z-A", label: "Alphabetical: Z-A" },
    { value: "low-players", label: "Players: Low to High" },
    { value: "high-players", label: "Players: High to Low" },
    { value: "high-average", label: "Average: High to Low" },
    { value: "low-average", label: "Average: Low to High" },
    { value: "low-price", label: "Price: Low to High" },
    { value: "high-price", label: "Price: High to Low" },
  ]

  return (
    <Select
      closeMenuOnSelect={true}
      isClearable={false}
      isSearchable={false}
      options={sortOptions}
      onChange={e => {
        return setSortBy(e.value)
      }}
      placeholder={"Sort by..."}
      styles={customStylesHomePage}
    />
  )
}

export default SortSearch
