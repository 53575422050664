import React, { useState, useEffect } from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
import ScrollToTop from "react-scroll-to-top"
import Img from "gatsby-image"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TagSearch from "../components/tagSearch"
import SortSearch from "../components/sortSearch"
import LowPrice from "../utils/lowPrice"
import compareFunc from "../utils/compareFunc"
import removeDuplicateTags from "../utils/removeDuplicateTags"
import getCurrency from "../utils/getCurrency"
import FGCTitleToggler from "../components/fgcTitle/index"
import { SearchInput, SearchWrapper } from "../components/styled/search"
import Slider from "../components/slider"
import { NoData } from "../components/styled/noData"
import { Container } from "../components/styled/container"
import { Banner, BannerWrapper } from "../components/styled/banner"
import DiscordWidget from "../components/discord/index"
import OneDayAverage from "../components/oneDayAverage/index"
import Ad from "../components/adsense/index"

const GameCard = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 24px;
  background-color: ${props =>
    props.isOdd
      ? props.theme.colors.gameCardBgLight
      : props.theme.colors.gameCardBg};

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`

const GameCardContent = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  grid-gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
  grid-auto-flow: row;

  @media (max-width: 576px) {
    text-align: center;
    margin-top: -7px;
    padding-top: 0;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .price-field {
    &:hover {
      p {
        color: #55f000;
      }
    }
  }

  .players-field {
    &:hover {
      p {
        color: #fffd00;
      }
    }
  }
`

const GameCardTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.gameCardTitle};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
`

const GameCardData = styled.p`
  color: ${props => props.color};
  margin-bottom: 0;
`

const Link = styled(GatsbyLink)`
  text-decoration: none;
  margin: 0;
`

const GameImage = styled(Img)`
  height: 100% !important;
  @media (max-width: 576px) {
    height: 200px !important;
    width: 100% !important;
  }
`

const IndexPage = ({ data, currency, fgcTitle, toggleFGCTitle }) => {
  const [query, searchQuery] = useState("")
  const [games, setGames] = useState(data.allStrapiGame.edges)
  const [tags, filterTags] = useState([]) // Renders them inside select component
  const [selectedTags, selectTag] = useState([])
  const [filteredGames, setFilteredGames] = useState([]) // Filtered Games
  const [sortBy, setSortBy] = useState("high-players")

  useEffect(() => {
    // Probably fails if there are no games at the start

    games.map(({ node }) => {
      return node.tags.forEach(tag => {
        tags.push({
          value: tag,
          label: tag,
        })
      })
    })

    filterTags(removeDuplicateTags(tags))
  }, [])

  useEffect(() => {
    if (selectedTags !== null && selectedTags.length > 0) {
      let checker = (arr, target) => target.every(v => arr.includes(v))
      let currentTags = selectedTags.map(({ value }) => value)
      const matchOnTag = games.filter(({ node }) => {
        return checker(node.tags, currentTags)
      })

      setFilteredGames(
        matchOnTag.filter(({ node }) => {
          return node.title.toLowerCase().includes(query.toLowerCase())
        })
      )
    } else {
      setFilteredGames(
        games.filter(({ node }) => {
          return node.title.toLowerCase().includes(query.toLowerCase())
        })
      )
    }
  }, [query, games])

  useEffect(() => {
    if (selectedTags !== null && selectedTags.length > 0) {
      searchQuery("")
      let checker = (arr, target) => target.every(v => arr.includes(v))
      let currentTags = selectedTags.map(({ value }) => value)
      setFilteredGames(
        games.filter(({ node }) => {
          return checker(node.tags, currentTags)
        })
      )
    } else {
      searchQuery("")
      setFilteredGames(games)
    }
  }, [selectedTags])

  useEffect(() => {
    const setFGCTitle = () => {
      const storedFGCTitleVal = localStorage.getItem("fgctitle")

      if (storedFGCTitleVal === "false" && fgcTitle === false) {
        return toggleFGCTitle()
      }
    }

    setFGCTitle()
  }, [])

  return (
    <Layout location="home">
      <BannerWrapper>
        <Banner
          left
          style={{
            overflow: "hidden",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              margin: 16,
              display: "grid",
              gridTemplateRows: "1fr 1fr 1fr 1fr",
              gridGap: 800,
            }}
          >
            <Ad slot="7846765506" className="el-left" />
            <Ad slot="7846765506" className="el-left" />
            <Ad slot="7846765506" className="el-left" />
            <Ad slot="7846765506" className="el-left" />
          </div>
        </Banner>

        <Container main>
          <SEO title="FGCharts" description={data.strapiSeo.homepage} />

          <ScrollToTop
            smooth
            color="white"
            top={300}
            style={{
              backgroundColor: "#111021",
              borderRadius: 0,
              border: "2px solid #4960EA",
            }}
          />
          <Slider />

          <SearchWrapper extraCol>
            <TagSearch selectTag={selectTag} tags={tags} />
            <SortSearch setSortBy={setSortBy} />
            <SearchInput
              placeholder="Search games..."
              onChange={e => searchQuery(e.target.value)}
              value={query}
            />
          </SearchWrapper>
          <FGCTitleToggler
            toggleFGCTitle={toggleFGCTitle}
            fgcTitle={fgcTitle}
          />

          {filteredGames.length > 0 ? (
            filteredGames
              .filter(game => {
                if (fgcTitle === true) {
                  return game.node.fgcTitle === true
                } else {
                  return game
                }
              })
              .sort((a, b) => {
                return compareFunc(a, b, sortBy)
              })
              .map((node, index) => {
                return (
                  <Link to={`/games/${node.node.slug}`} key={index}>
                    <GameCard isOdd={Boolean(index % 2)}>
                      <GameImage
                        fixed={node.node.banner.childImageSharp.fixed}
                      />
                      <GameCardContent>
                        <div
                          data-tip={`${
                            node.node.title.length > 17 ? node.node.title : ""
                          }`}
                          data-text-color="#FBFBFC"
                          data-border-color="#4960EA"
                          data-border={true}
                          data-background-color="#2B2A34"
                        >
                          <GameCardTitle>Game</GameCardTitle>
                          <GameCardData color="#FBFBFC">
                            {node.node.title.length > 17 ? (
                              <span>
                                {node.node.title
                                  .substring(0, 17)
                                  .replace(
                                    /\b[a-z]|['_][a-z]|\B[A-Z]/g,
                                    function (x) {
                                      return x[0] === "'" || x[0] === "_"
                                        ? x
                                        : String.fromCharCode(
                                            x.charCodeAt(0) ^ 32
                                          )
                                    }
                                  )}
                                <span style={{ color: "#4960EA" }}>…</span>
                              </span>
                            ) : (
                              node.node.title
                            )}
                          </GameCardData>
                        </div>
                        <div>
                          <GameCardTitle>Current Players</GameCardTitle>
                          <GameCardData color="#F19954">
                            {node.node.currentPlayersCount}
                          </GameCardData>
                        </div>
                        <div>
                          <GameCardTitle>24-hour Average</GameCardTitle>
                          <GameCardData color="#FFCD56">
                            <OneDayAverage
                              timestamps={node.node.timestamps.slice(-24)}
                            />
                          </GameCardData>
                        </div>
                        <AnchorLink
                          to={`/games/${node.node.slug}#prices`}
                          title="Check prices!"
                          className="stripped"
                          stripHash
                        >
                          <div className="price-field">
                            <GameCardTitle>Current Lowest Price </GameCardTitle>
                            <GameCardData color="#8FED6E">
                              {node.node.affiliates.length === 0 ? (
                                "FREE"
                              ) : (
                                <LowPrice
                                  price={getCurrency(
                                    node.node.affiliates,
                                    currency
                                  )}
                                  currency={currency}
                                />
                              )}
                            </GameCardData>
                          </div>
                        </AnchorLink>
                        <div
                          data-tip={`${
                            node.node.tags.length > 1
                              ? node.node.tags.map(
                                  tag =>
                                    `<smalll style='padding-left:3px'>${tag}</small>`
                                )
                              : ""
                          }`}
                          data-text-color="#FBFBFC"
                          data-border-color="#4960EA"
                          data-border={true}
                          data-background-color="#2B2A34"
                          data-class="tags-tooltip"
                          data-html={true}
                        >
                          <GameCardTitle>
                            tags ({node.node.tags.length})
                          </GameCardTitle>
                          {node.node.tags.slice(0, 1).map((tag, index) => {
                            return (
                              <small
                                key={index}
                                style={{
                                  color: "#FBFBFC",
                                  background: "#4960EA",
                                  padding: 4,
                                  marginRight: 6,
                                  fontWeight: 700,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {tag}
                              </small>
                            )
                          })}

                          {node.node.tags.length - 1 > 0 ? (
                            <small
                              style={{
                                color: "#FBFBFC",
                                padding: 4,
                                fontWeight: 700,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {node.node.tags.length - 1}+
                            </small>
                          ) : undefined}
                        </div>
                      </GameCardContent>
                    </GameCard>
                    <ReactTooltip />
                  </Link>
                )
              })
          ) : (
            <NoData>There are currently no games that fit the criteria.</NoData>
          )}
          <p id="games-tracking" style={{ marginBottom: 0, marginTop: 16 }}>
            FGCharts is currently tracking <strong>{games.length}</strong>{" "}
            games.{" "}
          </p>
        </Container>
        <Banner style={{ overflow: "hidden" }}>
          <div
            style={{
              overflow: "hidden",
              margin: 16,
              display: "grid",
              gridTemplateRows: "1fr 1fr 1fr 1fr",
              gridGap: 800,
            }}
          >
            <DiscordWidget />
            <Ad slot="6517054732" className="el-right" />
            <Ad slot="7846765506" className="el-left" />
            <Ad slot="7846765506" className="el-left" />
          </div>
        </Banner>
      </BannerWrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiGame(sort: { fields: currentPlayersCount, order: DESC }) {
      edges {
        node {
          title
          slug
          tags
          fgcTitle
          appId
          id
          affiliates {
            price
            discountPrice
            storeName
            refLink
            currency
          }
          strapiId
          banner {
            childImageSharp {
              fixed(width: 168, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          currentPlayersCount
          timestamps {
            y
          }
        }
      }
    }
    allStrapiBanners {
      edges {
        node {
          url_one
          url_two
          banner_one {
            url
          }
          banner_two {
            url
          }
        }
      }
    }
    strapiSeo {
      homepage
    }
  }
`

const mapStateToProps = state => ({
  currency: state.currency,
  fgcTitle: state.fgcTitle,
})

const mapDispatchToProps = dispatch => {
  return {
    toggleFGCTitle: () => dispatch({ type: "TOGGLE_FGCTITLE" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
