import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Toggle from "react-toggle"
import "react-toggle/style.css"

const Wrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Checkbox = styled.input`
  height: 24px;
  width: 24px;
`

const Label = styled.label`
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;

  span {
    margin-right: 12px;
  }

  .custom-classname.react-toggle--checked .react-toggle-track {
    background-color: #a5a8ac;
  }

  .react-toggle-track:hover {
    background-color: #a5a8ac;
  }

  .react-toggle-thumb {
    background-color: ${props => props.theme.colors.primary};
    border-color: transparent !important;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 2px 2px #868cff !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    border-color: transparent !important;
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 2px #868cff !important;
  }
`

const FGCTitleToggler = ({ toggleFGCTitle, fgcTitle }) => {
  const data = useStaticQuery(graphql`
    {
      strapiLabel {
        text
      }
    }
  `)
  return (
    <Wrapper>
      <Label>
        <span>{data.strapiLabel.text}</span>
        <Toggle
          defaultChecked={fgcTitle}
          checked={fgcTitle}
          className="custom-classname"
          onChange={e => {
            toggleFGCTitle(!fgcTitle)
            localStorage.setItem("fgctitle", fgcTitle)
          }}
        />
      </Label>
    </Wrapper>
  )
}

export default FGCTitleToggler
