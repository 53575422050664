import React from "react"

const DiscordWidget = () => {
  return (
    <iframe
      className="el-left"
      src="https://discord.com/widget?id=717397148416737361&theme=dark"
      style={{ width: "100%", height: 400 }}
      allowtransparency="true"
      frameBorder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    ></iframe>
  )
}

export default DiscordWidget
