import React from "react"
import { graphql, Link as GatsbyLink, StaticQuery } from "gatsby"
import styled from "styled-components"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import BackgroundImage from "gatsby-background-image"
import useWindowSize from "../utils/useWindowSize"

const Link = styled(GatsbyLink)`
  text-decoration: none;
`

const CarouselContainer = styled.div`
  padding-top: 24px;

  .BrainhubCarouselItem {
    display: block !important;
    max-height: 108px;
    overflow: hidden;
  }
  .BrainhubCarousel__arrows {
    background: transparent;

    :hover {
      background: transparent;
    }
  }
`

const Slider = () => {
  const { width } = useWindowSize()
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allStrapiArticle(limit: 2, sort: { fields: createdAt, order: DESC }) {
            edges {
              node {
                title
                slug
                author
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 360, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          allStrapiPost(limit: 2, sort: { fields: createdAt, order: DESC }) {
            edges {
              node {
                title
                slug
                author
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 360, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          allStrapiPostNew(limit: 2, sort: { fields: createdAt, order: DESC }) {
            edges {
              node {
                title
                slug
                author
                game {
                  banner {
                    childImageSharp {
                      fluid(maxWidth: 360, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allStrapiArticle, allStrapiPostNew, allStrapiPost }) => {
        let posts = []

        allStrapiArticle.edges.map(({ node }) => {
          return posts.push({
            title: node.title,
            author: node.author,
            slug: node.slug,
            featuredImage: node.featuredImage,
            toggle: false,
          })
        })
        allStrapiPostNew.edges.map(({ node }) => {
          return posts.push({
            title: node.title,
            author: node.author,
            slug: node.slug,
            game: node.game,
            toggle: true,
          })
        })
        allStrapiPost.edges.map(({ node }) => {
          return posts.push({
            title: node.title,
            author: node.author,
            slug: node.slug,
            featuredImage: node.featuredImage,
            toggle: true,
          })
        })

        return (
          <CarouselContainer>
            <Carousel
              slidesPerScroll={1}
              slidesPerPage={3}
              infinite
              arrows
              autoPlay={3000}
              animationSpeed={1000}
              breakpoints={{
                640: {
                  slidesPerPage: 1,
                  arrows: false,
                },

                1164: {
                  slidesPerPage: 1,
                  arrows: true,
                },
              }}
            >
              {posts.map((node, index) => {
                return (
                  <Link
                    to={
                      node.toggle
                        ? `/news/${node.slug}`
                        : `/articles/${node.slug}`
                    }
                    key={index}
                  >
                    <BackgroundImage
                      fluid={
                        node.game
                          ? node.game.banner.childImageSharp.fluid
                          : node.featuredImage.childImageSharp.fluid
                      }
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div
                        style={{
                          padding: 24,
                          backgroundColor: "rgba(0,0,0,.6)",
                        }}
                      >
                        <h3 style={{ marginBottom: 0, lineHeight: "40px" }}>
                          {width > 500
                            ? node.title.length >= 20
                              ? `${node.title.substring(0, 24)}...`
                              : node.title
                            : node.title.length >= 20
                            ? `${node.title.substring(0, 15)}...`
                            : node.title}
                        </h3>

                        <small
                          style={{
                            backgroundColor: "#111021",
                            borderRadius: 4,
                            padding: 6,
                            color: "#F84F68",
                            fontWeight: "bold",
                          }}
                        >
                          NEW
                        </small>
                      </div>
                    </BackgroundImage>
                  </Link>
                )
              })}
            </Carousel>
          </CarouselContainer>
        )
      }}
    />
  )
}

export default Slider
