const LowPrice = ({ price, currency }) => {
  let val = currency
    ? new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(
        Math.min.apply(
          Math,
          price.map(price => price.price)
        )
      )
    : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(
        Math.min.apply(
          Math,
          price.map(price => price.price)
        )
      )

  if (val === "0,00 €" || val === "$0.00") {
    return "Free"
  } else {
    return val
  }
}

export default LowPrice
