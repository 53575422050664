import styled from "styled-components"

export const BannerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* align-items: center; */

  @media (max-width: 1364px) {
    display: block;
    .el-right,
    .el-left {
      display: none;
    }
  }

  @media (max-width: 1500px) {
    .el-right {
      display: none;
    }
  }
`

export const Banner = styled.div`
  margin-top: 282px;
  text-align: ${props => (props.left ? "right" : "left")};
  width: 100%;
  padding: 16px;
  align-self: start;

  @media (max-width: 1440px) {
    margin-top: 296px;
  }

  img {
    margin-bottom: 0;
  }

  @media (max-width: 1364px) {
    display: none;
  }
`
